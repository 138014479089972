import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {mq} from 'styles/theme';
import Grid from './containers/Grid';
import Button from './Button';
import Ig from 'assets/icons/ig_color.svg';

const StyledWrapper = styled.section`
  position: sticky;
  top: 0;
  display: grid;
  grid-gap: 40px;
  padding: 60px 0;
  justify-items: center;

  ${({theme}) => theme.mq.m} {
    position: static;
    grid-gap: 20px;
    padding: 30px 0;
  }
`;

const StyledWrapperM = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 140px;
`;

const StyledTitle = styled.h1`
  font-size: 4rem;
  font-weight: 100;
  text-transform: uppercase;
  text-align: center;
  color: ${({theme}) => theme.color.black};
  letter-spacing: 3px;

  ${({theme}) => theme.mq.m} {
    font-size: 3rem;
    letter-spacing: 1px;
    position: sticky;
  }
`;

const StyledGrid = styled.dl`
  text-transform: uppercase;
  font-size: 2rem;
  max-width: ${({fullWidth}) => fullWidth ? '100%' : '180px'};
  color: ${({theme}) => theme.color.gray};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem 1rem;
  align-items: center;

  ${({theme}) => theme.mq.m} {
    font-size: 1.8rem;
  }

  ${({theme}) => theme.mq.s} {
    grid-gap: 2rem .75rem;
    font-size: 1.5rem;
    max-width: ${({fullWidth}) => fullWidth ? '100%' : '140px'};
  }

  dt {
    justify-self: left
  }

  dd {
    justify-self: right
  }
`;

const StyledArrow = styled.span`
  font-size: 2rem;
  line-height: 1rem;
  display: inline-block;
  height: 10px;
  margin-top: -5px;
  margin-right: 3px;
`;

const StyledIconWrapper = styled.dt`
  height: 24px;
`

const StyledIcon = styled(Ig)`
  height: 24px;
  width: 24px;
  box-sizing: content-box;
  transition: .3s ease;
  transition-delay: .15s;
  fill: ${({theme}) => theme.color.black};

  &:hover {
    transition-delay: 0s;
    fill: ${({theme}) => theme.color.grayLighter};
  }
`;

const StyledA = styled.a`
  color: #444444;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  grid-column: 1/3;
`

// const getFetchUrl = url => {
//   const igBase = "www.instagram.com/";
//   if (!url) return null;
//   if (!url.includes(igBase)) return null;
//   const regexp = new RegExp("(?<=www\\.instagram\\.com\\/)(.*)");
//   const [ username ] = url.match(regexp);
//   return `https://www.instagram.com/${username.split('/')[ 0 ]}/?__a=1`
// }

const DetailRow = ({data, label}) => {
  if (!data) return null;
  return (
    <>
      <dt>{label}</dt>
      <dd>{data}</dd>
    </>
  )
}

const InstagramRow = ({igUrl}) => {
  if (!igUrl) return null;
  const getIgName = () => {
    try {
      const [ , name ] = igUrl.split('instagram.com/')
      const [ nameRemovedSlash ] = name.split('/')
      return nameRemovedSlash.trim()
    } catch (err) {
      console.log(err)
    }
  }
  const igName = getIgName();
  return (
    <StyledA aria-label="instagram account" href={igUrl} target="_blank" rel="noreferrer noopener">
      <StyledIconWrapper>
        <StyledIcon/>
      </StyledIconWrapper>
      {igName && <dd>{igName}</dd>}
    </StyledA>
  )
}

const DetailsTable = ({details, igUrl}) => {
  const {sizes, gender} = details;
  return (
    <StyledGrid>
      <DetailRow data={sizes.height} label="height"/>
      <DetailRow data={sizes.bust} label={gender ==='male' ? "chest" : "bust"}/>
      <DetailRow data={sizes.waist} label="waist"/>
      <DetailRow data={sizes.hips} label="hips"/>
      <DetailRow data={sizes.shoe} label="shoe"/>
      <InstagramRow igUrl={igUrl}/>
    </StyledGrid>
  )
}


const ModelDetails = ({details, openForm}) => {
  const [ isM, setIsM ] = useState(false);
  // const [ ig, setIg ] = useState(null);

  const resizeHandler = useCallback(() => {
    if (typeof window !== 'undefined') {
      const check = window.innerWidth <= mq.m;
      setIsM(check);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      resizeHandler();
      window.addEventListener('resize', resizeHandler);
      return () => window.removeEventListener('resize', resizeHandler);
    }
    return null;
  }, []);

  // useEffect(() => {
  //   ( async () => {
  //     const url = getFetchUrl(details.instagramAccount);
  //     if (!url) {
  //       setIg(false);
  //       return;
  //     }
  //     try {
  //       const response = await fetch(url);
  //       const {graphql} = await response.json();
  //       setIg(graphql.user.edge_followed_by.count);
  //     } catch (error) {
  //       setIg(false)
  //     }
  //   } )();
  // }, [])
  if (isM) {
    return (
      <Grid cols="1fr 1fr" padding="20px 0" gap="40px" gapM="30px" gapS="15px" align="stretch">
        <DetailsTable details={details}/>
        <StyledWrapperM>
          <StyledTitle>{details.name}</StyledTitle>
          <StyledGrid fullWidth>
            <InstagramRow igUrl={details.instagramAccount}/>
          </StyledGrid>
          <Button component="button" onClick={openForm} inverted color="medium">
            BOOK&nbsp;NOW
          </Button>
          <Button component="link" to="/models" color="medium">
            ALL&nbsp;MODELS
          </Button>
        </StyledWrapperM>
      </Grid>
    );
  }
  return (
    <StyledWrapper>
      <Button component="link" to="/models" color="medium">
        <StyledArrow>&#x2190;</StyledArrow>
        ALL&nbsp;MODELS
      </Button>
      <StyledTitle>{details.name}</StyledTitle>
      <DetailsTable details={details} igUrl={details.instagramAccount}/>
      <Button component="button" onClick={openForm} inverted color="medium">
        BOOK NOW
      </Button>
    </StyledWrapper>
  );
};

export default ModelDetails;
