import React, { useState } from 'react';
import styled from 'styled-components';
import { Field, Form } from 'react-final-form';
import isEmail from 'validator/es/lib/isEmail';
import useBodyLock from '../helpers/useBodyLock';
import Input from './Input';
import Checkbox from './Checkbox';
import Textarea from './Textarea';
import Button from './Button';

const StyledWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 1000;
  overflow-y: hidden;
`;

const StyledOverlay = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  z-index: 1001;
  background: rgba(0,0,0,0.5);
`;

const StyledFormWrapper = styled.div`
  height: 100vh;
  width: 100%;
  max-width: 450px;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  z-index: 1002;
  background: ${({ theme }) => theme.color.black};
  display: flex;
  padding: 70px 30px 40px 30px;
  flex-direction: column;
  overflow-y: auto;
  h1 {
    color: ${({ theme }) => theme.color.white};
    text-align: center;
    font-size: 2.8rem;
    font-weight: 700;
    padding-bottom: 40px;
  }
  form {
    display: grid;
    grid-gap: .75rem;
  }
`;

const StyledClose = styled.button`
  background: none;
  border: none;
  position: absolute;
  height: 40px;
  width: 40px;
  padding: 10px;
  top: 0;
  right: 10px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:after, &:before {
    content: '';
    position: absolute;
    height: 1px;
    width: 30px;
    background: ${({ theme }) => theme.color.white};
    bottom: 50%;
    right: 0;
    transform-origin: center;
  }
  &:after {transform: rotate(45deg)}
  &:before {transform: rotate(-45deg)}
`;

const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const StyledFeedback = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme, indent }) => (indent === 'success' ? theme.color.white : 'firebrick')};
  text-align: center;
`;

const BookForm = ({ closeForm, name }) => {
  useBodyLock();
  const [sending, setSending] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [feedback, setFeedback] = useState(false);

  const validate = values => {
    const errors = {};
    if (!values.company) { errors.company = 'Pole wymagane'; }
    if (!values.email) {
      errors.email = 'Pole wymagane';
    } else if (!isEmail(values.email)) {
      errors.email = 'Podaj poprawny email';
    }
    if (!values.date) { errors.date = 'Pole wymagane'; }
    setDisabled(false);
    return errors;
  };

  const onSubmit = async values => {
    const valuesWithName = { ...values, name };
    const body = JSON.stringify(valuesWithName);
    setSending(true);
    setDisabled(true);
    setFeedback(false);
    const { status } = await fetch('https://services.onmove.pl/book', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    });
    setSending(false);
    if (status === 200) {
      setFeedback({ indent: 'success', msg: 'Zgłoszenie zostało przesłane' });
      setTimeout(closeForm, 3000);
    } else {
      setFeedback({
        indent: 'error',
        msg: 'Wystąpił problem z wysłaniem wiadmomości, spróbuj ponownie lub napisz na contact@onmove.pl',
      });
      setDisabled(false);
    }
  };

  return (
    <StyledWrapper>
      <StyledOverlay onClick={closeForm} />
      <StyledFormWrapper>
        <StyledClose onClick={closeForm} />
        <h1>BOOK NOW</h1>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field name="company">{ props => <Input label="firma" inverted narrow {...props} />}</Field>
              <Field name="email">{ props => <Input label="email" inverted narrow {...props} />}</Field>
              <Field name="date">{ props => <Input label="data sesji" inverted narrow {...props} />}</Field>
              <p>Zakres praw</p>
              <Field name="e_commerce" type="checkbox">
                {props => <Checkbox inverted {...props}>Sklep internetowy</Checkbox>}
              </Field>
              <Field name="look_book" type="checkbox">
                {props => <Checkbox inverted {...props}>Look book</Checkbox>}
              </Field>
              <Field name="campaign" type="checkbox">
                {props => <Checkbox inverted {...props}>Kampania</Checkbox>}
              </Field>
              <Field name="print" type="checkbox">
                {props => <Checkbox inverted {...props}>Druk</Checkbox>}
              </Field>
              <Field name="message">
                { props => <Textarea inverted narrow label="wiadomość" {...props} /> }
              </Field>
              <StyledButtonWrapper>
                <Button
                  disabled={disabled || sending}
                  loading={sending}
                  inverted
                  color="light"
                  component="button"
                  type="submit"
                >
                  WYŚLIJ
                </Button>
              </StyledButtonWrapper>
              <StyledFeedback indent={feedback && feedback.indent}>
                {feedback && feedback.msg}
              </StyledFeedback>
            </form>
          )}
        />
      </StyledFormWrapper>
    </StyledWrapper>
  );
};

export default BookForm;
