import React, { useState } from 'react';
import styled from 'styled-components';
import ExpandedImage from './ExpandedImage';
import GatsbyImage from "gatsby-image";

const StyledWrapper = styled.div`
  padding: 5px;
  cursor: pointer;
  img {
    display: inline-block;
    width: 100%;
  }
`;

const ImageResize = ({ thumbnail, main }) => {
  const [expanded, setExpanded] = useState(false);

  const open = () => setExpanded(true);
  const close = () => setExpanded(false);

  return (
    <StyledWrapper>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <div onClick={open} role={"button"}>
        <GatsbyImage fluid={thumbnail}/>
      </div>
      {expanded && (
        <ExpandedImage src={main} onClose={close} />
      )}
    </StyledWrapper>
  );
};

export default ImageResize;
